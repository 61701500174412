import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useMiscStore } from "@/stores/misc";
import { useUserStore } from "@/stores/user";
import { TokenResultDto } from "@/api-client";

function isValid(date:any) {
  return date && new Date().getTime() < new Date(date).getTime()
}

export function ConfigureAxios() {
  let authStore: any = null;
  let miscStore: any = null;
  let userStore: any = null;
  let hasCompleted = false;

  axios.interceptors.request.use(
    (req) => {
      miscStore = useMiscStore();
      authStore = useAuthStore();
      userStore = useUserStore();
      hasCompleted = false;

      setTimeout(() => {
        if(!hasCompleted) {
          miscStore.isLoading = true;
        }
      }, 100);
      

      const url = new URL(req.url!);

      if (url.pathname.includes("/Account")) return req;

      let tkn = authStore.token;
      let expiration = userStore.expiry;

      if(tkn !== null){
        if(!tkn.refreshToken) {
          authStore.logout() //clear login data (token, profile etc) without actually logging out
          tkn = null
          return req
        }
        if(!isValid(expiration)) {
          return authStore.refresh().then((res: TokenResultDto) => {
            // TODO check that it works
            tkn = res;
            req.headers!.Authorization = `Bearer ${tkn.authenticationToken}`;
            return req;
          });
        }
        else if(isValid(expiration) && tkn.authenticationToken){
          req.headers!.Authorization = `Bearer ${tkn.authenticationToken}`;
        }
      }
      return req;
    },
    async (error) => {
      hasCompleted = true;
      miscStore.isLoading = false;
      miscStore.isLoadingOverride = false;
      console.log("ded");
      return Promise.reject(error);
    }
  );
  //TODO https://dev.to/franciscomendes10866/how-to-use-axios-interceptors-b7d



  axios.interceptors.response.use(
    (response) => {
      hasCompleted = true;
      miscStore.isLoading = false;
      return response;
    },
    async (error) => {
      hasCompleted = true;
      miscStore.isLoading = false;
      miscStore.isLoadingOverride = false;
      let originalRequestRetry = false;
      const status = error?.response ? error.response.status : null;

      if ([401].includes(status) && !originalRequestRetry) {
        originalRequestRetry = true;
        return authStore.refresh().then(() => {
          console.log("Here " + status);
          return axios.request(error.config);
        });
      } else {
        return Promise.reject(error);
      }
    }
  );
}


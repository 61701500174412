import { SendUpdateOtpDto, CustomersDto, UpdateCustomerDto, ApiCustomersIdMobilenumberPutRequest, ApiCustomersIdEmailPutRequest, CustomersUpdateMobileNumberDto, CustomersUpdateEmailDto, MessageType, PagedResultOfCustomerOrdersDto, OrderInvoiceHtmlDto } from "@/api-client";
import { Customers, CustomerOtps, CustomersServices } from "@/network/api";
import { ElMessage } from "element-plus";
import { handleError } from "@/utilities/api";
import { scrollTop } from "@/utilities";
import { useUserStore } from "@/stores/user";

export function getCustomer(id: string): Promise<CustomersDto> {
  return new Promise((resolve, reject) => {
    Customers.apiCustomersIdGet(id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function getCustomerOrders(id: string, pageNumber: number = 1, pageSize: number = 5): Promise<PagedResultOfCustomerOrdersDto> {
  return new Promise((resolve, reject) => {
    Customers.apiCustomersIdOrdersGet(pageNumber, pageSize, id)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function getCustomerInvoice(id: string, invoiceId: string): Promise<OrderInvoiceHtmlDto> {
  return new Promise((resolve, reject) => {
    CustomersServices.apiCustomersIdInvoicesInvoiceIdGet(id, invoiceId)
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function updateCustomer(item: UpdateCustomerDto): Promise<void> {
  return new Promise((resolve, reject) => {
    Customers.apiCustomersIdPut(item.id, {id: item.id, dto: item})
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          const userStore = useUserStore();
          if(userStore.name && userStore.name !== item.firstName) {
            userStore.setName(item.firstName);
          }

          ElMessage.success({ message: "Profile updated!", showClose: true, grouping: true });
          scrollTop();
          resolve();
        }
      })
      .catch((error) => {
        handleError(error);
        // reject(error);
      });
  });
}

export function sendCustomerOtp(item: SendUpdateOtpDto): Promise<void> {
  return new Promise((resolve, reject) => {
    CustomerOtps.apiCustomersIdSendOtpPost(item.customersId, {id: item.customersId, dto: item})
      .then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          ElMessage.success({ message: "OTP sent!", showClose: true, grouping: true });
          resolve(res.data);
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function updateCustomerContact(type: MessageType, item: CustomersUpdateMobileNumberDto | CustomersUpdateEmailDto, isGeneralForm: boolean = false): Promise<void> {
  return new Promise((resolve, reject) => {
    if (type == "SMS") {
      Customers.apiCustomersIdMobilenumberPut(item.id, {id: item.id, dto: item} as ApiCustomersIdMobilenumberPutRequest)
        .then((res) => {
          if ([200, 201, 204].includes(res.status)) {
            ElMessage.success({ message: "Cellphone number updated!", showClose: true, grouping: true });
            resolve(res.data);
          }
        })
        .catch((error) => {
          handleError(error);
          reject(error);
        });
    } else if (type == "Email") {
      Customers.apiCustomersIdEmailPut(item.id, {id: item.id, dto: item} as ApiCustomersIdEmailPutRequest)
        .then((res) => {
          if ([200, 201, 204].includes(res.status)) {
            ElMessage.success({ message: "Email address updated!", showClose: true, grouping: true });
            resolve(res.data);
          }
        })
        .catch((error) => {
          handleError(error);
          reject(error);
        });
    }
  });
}


import { computed } from "vue";
import { EpPropMergeType } from "element-plus/es/utils";
import { useRoute } from 'vue-router';

export function scrollTop(toScrollSelector = "", scrollIfAbove = true) {
  if (toScrollSelector) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    if (destination) {
      const navBar = document.querySelector("#navbar") as HTMLElement;
      
      const scrollOffset = destination.offsetTop - (navBar ? navBar.offsetHeight : 0) - 10;

      if (scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }

      window.scrollTo(0, scrollOffset);
    }
  } else {
    window.scrollTo(0, 0);
  }

  return;
}


export function formatDate(date: string, separator = "/") {
  if(date) {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${appendLeadingZeroes(day)}${separator}${appendLeadingZeroes(month)}${separator}${year}`;
  }
  return ""
}

export function formatTime(date: string) {
  if(date) {
    const dateObject = new Date(date);
    let hours: string | number = appendLeadingZeroes(dateObject.getHours())
    let minutes: string | number = appendLeadingZeroes(dateObject.getMinutes())
    return `${hours}:${minutes}`
  }
  return ""
}

function appendLeadingZeroes(n: any) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function print() {
  window.print();
}

export function checkStore(state: any, stateItem: string, storeName: string, getterName: string) {
  if (state && state[stateItem] != null) {
    return state[stateItem];
  } else {
    const pinia = localStorage.getItem("pinia");
    const store = JSON.parse(pinia!);
    if (store == null) {
      return null;
    }
    if (store[storeName] == null) {
      return null;
    }
    const storageItem = store[storeName][getterName];
    if (storageItem != null) {
      return storageItem;
    }

    return null;
  }
}

export function statusFilter(status: any) {
  const statusMap: { [key: string]: string } = {
    false: "success",
    true: "danger",
  };
  return statusMap[status] as EpPropMergeType<StringConstructor, "" | "success" | "warning" | "info" | "danger", unknown> | undefined;
}

export const disabledDate = (time: Date) => {
  const today = new Date();
  let minDate = today.setDate(today.getDate());

  return time.getTime() > minDate;
};

type AnyObject = { [key: string]: any };

export function copyMatchingProperties<T extends AnyObject, U extends AnyObject>(
    source: T,
    destination: U
): U {
    const result = { ...destination };

    for (const key in source) {
        // if (key in destination) {
        //     (result[key] as T) = source[key];
        // }

        if (typeof source[key] === 'object' && typeof result[key] === 'object') {
          copyMatchingProperties(source[key], result[key]);
        } else {
          (result[key] as T) = source[key];
        }
    }

    return result;
}

import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import { createHead } from "@unhead/vue";
import ElementPlus from "element-plus";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import "../scss/app.scss";


/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faWifi, faPenToSquare, faInfoCircle  } from '@fortawesome/free-solid-svg-icons'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp  } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faWhatsapp, faCircleUser, faWifi, faPenToSquare, faInfoCircle)

import { ConfigureAxios } from "@/network/auth-interceptor";

// Set 1st day of week to Monday for Element UI date pickers
dayjs.Ls.en.weekStart = 1;
dayjs.extend(utc)
dayjs.extend(timezone)

ConfigureAxios();
const app = createApp(App);
const head = createHead();
const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$accessToken = {};

/* add font awesome icon component */
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(ElementPlus);
app.use(pinia);
app.use(head);
app.use(router);

app.mount("#app");

// import { hasAnyPermisson } from "@/utilities/auth";
import { ElMessage } from "element-plus";
import { useAuthStore } from "@/stores/auth";
import { createRouter, createWebHistory, RouteRecordRaw, RouteLocation, LocationQueryValue, RouteLocationNormalized } from "vue-router";
import DashboardView from "@/views/DashboardView.vue";
import ProfileView from "@/views/ProfileView.vue";
import ProductsView from "@/views/ProductsView.vue";
import LoginView from "@/views/LoginView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import RegisterView from "@/views/RegisterView.vue";
import EmailVerificationView from "@/views/EmailVerificationView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import InvoiceView from "@/views/InvoiceView.vue";
import CardRedirect from "@/components/misc/CardRedirect.vue";

function authGuard(to: RouteLocation) {
  const whiteList = ['Login', 'Register', 'ForgotPassword', 'EmailVerification', 'PageNotFound'];
  const authStore = useAuthStore();

  if (authStore.isLoggedIn || whiteList.includes(to.name as string)) {
    // logged in so return true
    return true;
  } else {
    // not logged in so redirect to login page with the return url
    if(to.name !== "Login") {
      router.push({ name: "Login", query: { returnUrl: to.fullPath  } });
    }
    return false;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/email-verification",
    name: "EmailVerification",
    component: EmailVerificationView,
    props: true
  },
  {
    path: "/",
    name: "Dashboard",
    component: DashboardView,
  },
  {
    path: "/my-profile",
    name: "Profile",
    component: ProfileView,
  },
  {
    path: "/my-products",
    name: "Products",
    component: ProductsView,
  },
  {
    path: "/card-update/:orderId/:checkoutId",
    name: "NewCard",
    component: CardRedirect,
    props: true,
  },
  {
    path: "/invoice/:invoiceId",
    name: "ViewInvoice",
    component: InvoiceView,
    props: true
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from) => {
  const authStore = useAuthStore();

  if(to.name !== "Login") {
    authGuard(to)
  } else if(to.name == "Login" && authStore.isLoggedIn) {
    const redirect = router.currentRoute.value.query.returnUrl as LocationQueryValue;
    if(redirect) {
      router.push({path: redirect})
    } else {
      router.push({name: 'Dashboard'})
    }
  }

  const isMaintainMessage = maintainMessage(to, from)
  if (!isMaintainMessage) {
    ElMessage.closeAll()
  }
  document.getElementById("navbarNav")?.classList?.remove("show");
});

function maintainMessage(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  if (from.query.order && to.name == "Products") {
    return true
  }
  return false
}

export default router;

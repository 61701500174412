import { defineStore } from "pinia";
import { Authentication, Customers } from "@/network/api";
import { checkStore } from "@/utilities/index";
import { ApiAccountConfirmEmailPostRequest, ApiAccountLoginPostRequest, ApiAccountRegisterPostRequest, ApiCustomersRegistrationPostRequest, OkResult, TokenResultDto } from "@/api-client";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { ElMessage } from "element-plus";
import { handleError } from "@/utilities/api";

type AuthState = {
  authToken: TokenResultDto | null;
  isLoggedIn: boolean;
};

let _refreshingToken: Promise<TokenResultDto> | null = null;

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => {
  return {
      authToken: null, // use getter straight out of localstorage
      isLoggedIn: checkStore(this, "authToken", "auth", "token") != null,
    };
  },
  actions: {
    // Mutations
    setToken(value: TokenResultDto | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["auth"]) {
        store["auth"] = {};
      }
      store["auth"]["token"] = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
      this.authToken = value || null;
      this.isLoggedIn = value ? true : false;
    },

    // Actions
    login: function (params: ApiAccountLoginPostRequest) {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountLoginPost({
          email: params.email,
          password: params.password,
        })
          .then(async (res) => {
            const userStore = useUserStore();
            let token = res.data;
            this.setToken(token);

            if(token.authenticationToken) {
              await userStore.getUserInfo(token.authenticationToken)
            }
            resolve();
          })
          .catch((error) => {
            console.error("LOGIN FAILED", error);
            // if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
            //   if(error.response?.status === 403) {
            //     ElMessage.error({ message: "Incorrect email or password.", showClose: true, grouping: true });
            //   }
            // }
            handleError(error, true, "Incorrect username and/ or password.")
            reject(error);
          });
      });
    },
    logout: function () {
      return new Promise<void>((resolve, reject) => {
        const userStore = useUserStore();
        this.setToken(null);
        userStore.clearUser().then(() => {
          router.push({name: "Login"});
          resolve()
        });
      });
    },
    refresh: function () {
      return (_refreshingToken ??= new Promise<TokenResultDto>((resolve, reject) => {
        const authenticationToken = this.token?.["authenticationToken"];
        const refreshToken = this.token?.["refreshToken"];

        if(authenticationToken && refreshToken) {
          Authentication.apiAccountRefreshTokenPost(authenticationToken, refreshToken)
          .then(async (res) => {
            if(res.data) {
              const userStore = useUserStore();
              let token = res.data;
              this.setToken(token);

              if(token.authenticationToken) {
                await userStore.getUserInfo(token.authenticationToken)
              }
              resolve(token);
            }
          })
          .catch(async (error) => {
            await this.logout().then(() => {
              ElMessage.error({ message: "Please login.", showClose: true, grouping: true });
              router.push({ name: 'Login', query: { returnUrl: router.currentRoute.value.fullPath || undefined  } });
            })
            console.error("Refresh FAILED", error);
            reject(error);
          });
        } else {
          reject()
        }
      }).finally(() => (_refreshingToken = null)));
    },
    sendMobileOTP: (params: { mobileNumber: string, orderReferenceNumber: string }) => {
      return new Promise<void>((resolve, reject) => {
        Customers.apiCustomersRegistrationMobileNumberGet(params.mobileNumber, params.orderReferenceNumber)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            }
          })
          .catch((error) => {
            console.error("REGISTER MOBILE FAILED", error);
            handleError(error)
            reject(error);
          });
      });
    },
    verifyOTP: (params: { otp: string, mobileNumber: string }) => {
      return new Promise<string>((resolve, reject) => {
        Customers.apiCustomersValidateOtpGet(params.otp, params.mobileNumber)
          .then((res) => {
            if(res.data) {
              resolve(res.data);
            }
          })
          .catch((error) => {
            console.error("VALIDATE OTP FAILED", error);
            handleError(error, true, "Error. Unable to validate OTP.");
            reject(error);
          });
      });
    },
    registerAccount: (params: ApiCustomersRegistrationPostRequest) => {
      return new Promise<void>((resolve, reject) => {
        Customers.apiCustomersRegistrationPost({
          email: params.email,
          password: params.password,
          customerId: params.customerId
        })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error("REGISTER EMAIL FAILED", error);
            handleError(error)
            reject(error);
          });
      });
    },
    // registerAccount: (params: ApiAccountRegisterPostRequest) => {
    //   return new Promise<void>((resolve, reject) => {
    //     Authentication.apiAccountRegisterPost({
    //       email: params.email,
    //       password: params.password
    //     })
    //       .then(() => {
    //         resolve();
    //       })
    //       .catch((error) => {
    //         console.error("REGISTER EMAIL FAILED", error);
    //         handleError(error)
    //         reject(error);
    //       });
    //   });
    // },
    resetPassword: (emailAddress: string, passwordResetToken: string, newPassword: string) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("RESET PASSWORD FAILED", error);
            handleError(error)
            reject(error);
          });
      });
    },
    forgotPassword: (emailAddress: string) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountForgotPasswordPost(emailAddress)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("FORGOT PASSWORD FAILED", error);
            handleError(error)
            reject(error);
          });
      });
    },
    emailVerification: (params: ApiAccountConfirmEmailPostRequest) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountConfirmEmailPost(params)
          .then((res) => {
            if ([200, 201, 204].includes(res.status)) {
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            console.log("EMAIL VERIFICATION FAILED", error);
            handleError(error, true, "Error. Unable to verify email address.");
            reject(error);
          });
      });
    },
  },
  getters: {
    token(state) {
      return checkStore(state, "authToken", "auth", "token");
    }
  },
});

import { ApiOrdersProductsMsisdnTopupPutRequest, OrderProductsViewModel, JsonResponseOfString, ApiOrdersOrderIdAddNewCardPostRequest } from "@/api-client";
import { Orders } from "@/network/api";
import { handleError } from "@/utilities/api";
import { ElMessage } from "element-plus";

export function ordersTopUpPut(msisdn: string, item: ApiOrdersProductsMsisdnTopupPutRequest): Promise<OrderProductsViewModel> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnTopupPut(msisdn, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        ElMessage.success({ message: "Action successful!", showClose: true, grouping: true });
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function ordersMigratePut(msisdn: string, item: ApiOrdersProductsMsisdnTopupPutRequest): Promise<OrderProductsViewModel> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnMigrationPut(msisdn, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        ElMessage.success({ message: "Action successful!", showClose: true, grouping: true })
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function ordersCancel(msisdn: string, cancelReasonId: number): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnCancelDelete(msisdn, cancelReasonId )
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        ElMessage.success({ message: "Action successful!", showClose: true, grouping: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export type OrderNewCardRef = {
  orderId: string;
  referenceNumber: string;
  checkoutId: string;
}

export function initiateCardChange(): Promise<JsonResponseOfString> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersInitiateCardChangePost()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function addNewCard(orderId: string, newCardRequest: ApiOrdersOrderIdAddNewCardPostRequest): Promise<JsonResponseOfString> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersOrderIdAddNewCardPost(orderId, newCardRequest)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      resolve({value: ''})
    });
  });
}
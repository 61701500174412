import { CancelationReasonsDto, ProductsListViewModel, PagedResultOfOrderPaymentViewModel } from "@/api-client";
import { CancelationReasons, Products, Payments } from "@/network/api";
import { handleError } from "@/utilities/api";

type Period = '_7Days' | '_3Months' | '_6Months' | '_12Months';

export function topUpDdlGet(): Promise<ProductsListViewModel[]> {
  return new Promise((resolve, reject) => {
    Products.apiProductsTopupListGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function migrateDdlGet(): Promise<ProductsListViewModel[]> {
  return new Promise((resolve, reject) => {
    Products.apiProductsMigrationListGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function cancelDdlGet(): Promise<CancelationReasonsDto[]> {
  return new Promise((resolve, reject) => {
    CancelationReasons.apiCancelationreasonsGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
    });
  });
}

export function ordersIdPaymentGet(
  pgNum: number = 1,
  pgSize: number = 20,
  ordersId: string,
  period?: Period,
  ): Promise<PagedResultOfOrderPaymentViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Payments.apiOrdersOrdersIdPaymentGet(pgNum, pgSize, ordersId, period)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}
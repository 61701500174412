<template>
  <div class="footer bg-dark no-print">
    <footer class="col-12">
      <div class="container my-4 d-flex flex-wrap justify-content-sm-around">
        <div class="col-12 col-sm-auto px-4 py-1">
          <div class="h5">Customer Support</div>
          <ul class="list-unstyled mb-0">
            <li>
              <a class="d-inline-flex align-items-center" target="_blank" href="https://wa.me/27826223564"><font-awesome-icon class="pe-2 fs-5" icon="fa-brands fa-whatsapp" /> +27 (0)82 622 3564</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-sm-auto px-4 py-1 mt-3 mt-sm-0">
          <div class="h5">Terms</div>
          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://vascoconnect.co.za/pages/terms-and-conditions" target="_blank">Terms & Conditions</a>
            </li>
          </ul>
        </div>
      </div>
      <hr class="m-0">
      <div class="container my-4">
        <div class="col-12 text-center">
          <div class="small">&#169; {{ new Date().getFullYear() }}, <a href="https://vascoconnect.co.za/" target="_blank">Vasco Connect</a></div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
$footerText: $tertiary;

.footer {
  color: $footerText !important;
  z-index: 149;
  font-family: "OpenSans", Helvetica, sans-serif;
  font-size: 14px;

  hr {
    border-top: solid .1rem rgba(white,.08);
    opacity: 1;
  }

  a {
    color: $tertiary !important;
    text-decoration: none;

    &:hover {
      &,
      * {
        @include media-breakpoint-up(lg) {
          text-decoration: underline;
          color: white !important;
        }
      }
    }
  }
}
</style>

import axios from 'axios';
import * as VASCOAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new VASCOAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API
});

const Authentication: VASCOAPI.AccountApi = new VASCOAPI.AccountApi(globalConf);
const Customers: VASCOAPI.CustomersApi = new VASCOAPI.CustomersApi(globalConf);
const CustomerOtps: VASCOAPI.CustomerOtpsApi = new VASCOAPI.CustomerOtpsApi(globalConf);
const CustomersServices: VASCOAPI.CustomersServicesApi = new VASCOAPI.CustomersServicesApi(globalConf);
const Orders: VASCOAPI.OrdersApi = new VASCOAPI.OrdersApi(globalConf);
const Products: VASCOAPI.ProductsApi = new VASCOAPI.ProductsApi(globalConf);
const CancelationReasons: VASCOAPI.CancelationReasonsApi = new VASCOAPI.CancelationReasonsApi(globalConf);
const Payments: VASCOAPI.PaymentsApi = new VASCOAPI.PaymentsApi(globalConf);

export { Authentication, Customers, CustomerOtps, CustomersServices, Orders, Products, CancelationReasons, Payments };
import { defineStore } from 'pinia';
import { checkStore } from "@/utilities/index";
import { ApplicationClaimType } from '@/api-client';
import { handleClaims } from '@/utilities/claims';
import { getCustomer } from "@/api/customer";

type UserState = {
  userRole: string | null;
  userExpiry: number | null;
  userId: string | null;
  userName: string | null;
  userClaims: Map<ApplicationClaimType, string | string[]> | null;
};

const getUserInfoFromToken = (token: string) => {
  return (new Promise<any>((resolve, reject) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const parsedJSON = JSON.parse(jsonPayload)
    resolve(parsedJSON);
  }))
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => {
    return {
      userRole: null,
      userExpiry: null,
      userId: null,
      userName: null,
      userClaims: null
    }
  },
  actions: {
    // Mutations
    setRole(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["role"] = value;
      this.userRole = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setExpiry(value: number | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["expiry"] = value;
      this.userExpiry = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setId(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["id"] = value;
      this.userId = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setName(value: string | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["name"] = value;
      this.userName = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },
    setClaims(value: Map<ApplicationClaimType, string | string[]> | null) {
      const pinia = localStorage.getItem("pinia");
      let store = JSON.parse(pinia!);
      if (!store) {
        store = {};
      }
      if (!store["user"]) {
        store["user"] = {};
      }
      store["user"]["claims"] = value;
      this.userClaims = value;
      localStorage.setItem("pinia", JSON.stringify({ ...store }));
    },

    // Actions
    getUserInfo: function (token: string) {
      return new Promise<void>(async (resolve, reject) => {
        const data = await getUserInfoFromToken(token);

        if (!data) {
          throw Error('Verification failed, please Login again.')
        }

        let role = data['role'];
        let expiry = data['exp'];
        let id = data['CustomersId'] || data['sub'];
        let claims = await handleClaims(data);
        let firstName = data['CustomersName'];       

        this.setRole(role);
        this.setExpiry(expiry * 1000);
        this.setId(id);
        this.setName(firstName || null)
        this.setClaims(claims);

        resolve()
      });
    },
    clearUser: async function () {
      this.setRole(null);
      this.setExpiry(null);
      this.setId(null);
      this.setClaims(null);
      this.setName(null);
      localStorage.clear();
    },
  },
  getters: {
    role(state) {
      return checkStore(state, "userRole", "user", "role");
    },
    expiry(state) {
      return checkStore(state, "userExpiry", "user", "expiry");
    },
    id(state) {
      return checkStore(state, "userId", "user", "id");
    },
    name(state) {
      return checkStore(state, "userName", "user", "name");
    },
    claims(state) {
      if (state && state["userClaims"] != null) {
        return state["userClaims"];
      } else {
        const pinia = localStorage.getItem("pinia");
        const store = JSON.parse(pinia!);
        if (store == null) {
          return null;
        }
        if (store["user"] == null) {
          return null;
        }

        const claims = store["user"]["userClaims"];
        if (claims != null) {
          const claimsMap: Map<ApplicationClaimType, string | string[]> = new Map(
            claims.map((obj:any) => {
              return [obj[0], obj[1]];
            }),
          );

          this.userClaims = claimsMap;
          return claimsMap;
        }
    
        return null;
      }
    }
  }
})

import { ProblemDetails } from "@/api-client";
import { ElMessage } from "element-plus";
import { useMiscStore } from '@/stores/misc';
import { ValidateFieldsError } from "async-validator";

export function handleError(error: ProblemDetails | any, displayMessage = true, defaultMsg = "An unexpected error has occurred.", duration = 3000) {
  console.log(error);

  const miscStore = useMiscStore();
  const defaultError = `<p>${defaultMsg}</p>`;
  let errorMessage = defaultError;

  setTimeout(() => {
    miscStore.isLoading = false;
    miscStore.isLoadingOverride = false;
  }, 500);

  if(displayMessage) {
    if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
      if(error.response?.data?.[""]) {
        errorMessage = formatErrors(error.response?.data?.[""])
      } else if(Array.isArray(error.response?.data) && error.response?.data?.length > 0) {
        errorMessage = `<p>${error.response?.data?.[0].errorMessage || defaultError}</p>`;
      } else {
        errorMessage = `<p>${handleDataType((error.response?.data as any)?.detail?.message || (error.response?.data as ProblemDetails)?.title || (error.response?.data as ProblemDetails)?.detail || error.response?.data) || defaultError}</p>`;
      }

      if(error.response?.data?.errors) {
        errorMessage = formatErrors(error.response?.data?.errors)
      }
    } else if (error) {
      errorMessage = error.message || defaultError;
    }

    ElMessage.error({ message: errorMessage, showClose: true, duration: duration, dangerouslyUseHTMLString: true });
  }
}

function formatErrors(errors:ProblemDetails):string {
  const errorMessages = errors;
  let result = "";

  if(Array.isArray(errors)) {
    let arr: string[] = []
    errors.forEach((e:string) => {
      arr.push(`<p class='m-0'>${e}</p>`);
    })
    result += arr.join("")
  } else {
    if(Object.keys(errorMessages).length) {
      let arr: string[] = []
      Object.keys(errorMessages).forEach((key:any) => {
        arr.push(`<p class='m-0'>${errorMessages[key]}</p>`);
      })
      result += arr.join("")
    }
  }

  return result;
}

function handleDataType(value:string | null) {
  if(value) {
    let json;
    let isJson = false;

    try {
      json = JSON.parse(value);
      if(typeof json == 'object') {
        isJson = true;
      }
    } catch (error) {
      isJson = false;
    }

    if(isJson && json) {
      let convertedJson = []
      for (const [key, value] of Object.entries(json)) {
        if(value) {
          convertedJson.push(`<u>${key}</u>: ${value}`);
        }
      }

      if (Object.prototype.hasOwnProperty.call(json, "message")) {
        return json.message;
      }

      return json;
    }

    return value;
  }
  return null;
}

// export function handleError(error: ProblemDetails | any, displayMessage = true) {
//   console.log(error);

//   const miscStore = useMiscStore();
//   const defaultError = "An unexpected error has occurred.";
//   let errorMessage = defaultError;

//   setTimeout(() => {
//     miscStore.isLoading = false;
//     // miscStore.setIsLoadingOverride(false);
//   }, 500);

//   if(displayMessage) {
//     if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
//       errorMessage =
//       (error.response?.data as ProblemDetails).title || (error.response?.data as ProblemDetails).detail  || defaultError;
//     } else if (error) {
//       errorMessage = error.message || defaultError;
//     }
  
//     ElMessage.error({ message: errorMessage, showClose: true, duration: 0, grouping: true });
//   }
// }

export function handleInvalidForm(fields: ValidateFieldsError) {
  if (fields) {
    const field = Object.values(fields)[0];
    if (field[0]?.field) {
      document.getElementsByName(field[0].field)[0]?.focus();
    }
  }
  console.log("invalid");
}
